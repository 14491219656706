import { CostBasisContext } from '@/components/CostBasisProvider';
import {
  ClosingStepStatus,
  ClosingStepType,
  useClosingPageStore,
} from '@/pages/closing/hooks/ClosingPageStore';
import { Flex, Popover, Text } from '@radix-ui/themes';
import { useCallback, useContext, useState } from 'react';
import { EnterpriseJobContext } from '../EnterpriseJobProvider';
import { SidebarMenuButton, SidebarMenuItem } from '../ui/sidebar';
import { ClosingProcessJobStatus } from './ClosingProcessJobStatus';
import { CostBasisJobStatus } from './CostBasisJobStatus';
import { JobStatusIcon } from './JobStatusIcon';
import { RulesJobStatus } from './RulesJobStatus';

export function GlobalJobStatus() {
  const { isCostBasisRunning, hasError } = useContext(CostBasisContext);
  const { isRulesEngineJobRunning } = useContext(EnterpriseJobContext);

  const { getCurrentStep, steps } = useClosingPageStore();
  const currentStep = steps.get(getCurrentStep());

  const isClosingProcessRunning =
    currentStep?.status === ClosingStepStatus.Running;
  const isClosingProcessFailed =
    currentStep?.status === ClosingStepStatus.Error;

  const [isOpen, setIsOpen] = useState(false);

  const onTogglePopover = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const isIdle =
    !isCostBasisRunning &&
    !hasError &&
    !isClosingProcessRunning &&
    !isClosingProcessFailed &&
    !isRulesEngineJobRunning;

  const getJobStatusText = () => {
    if (hasError || isClosingProcessFailed) {
      return 'Job failed';
    }

    if (isRulesEngineJobRunning) {
      return 'Applying rules';
    }

    if (isCostBasisRunning) {
      return 'Calculating';
    }
    if (isClosingProcessRunning) {
      if (currentStep?.step === ClosingStepType.Sync) {
        return 'Syncing';
      } else if (currentStep?.step === ClosingStepType.Lock) {
        return 'Locking period';
      } else if (currentStep?.step === ClosingStepType.Close) {
        return 'Closing period';
      }
    }
    return 'All synced';
  };

  return (
    <SidebarMenuItem>
      <Popover.Root open={isOpen} onOpenChange={onTogglePopover}>
        <Popover.Trigger>
          <SidebarMenuButton>
            <JobStatusIcon
              isRunning={
                isCostBasisRunning ||
                isClosingProcessRunning ||
                isRulesEngineJobRunning
              }
              hasError={hasError || isClosingProcessFailed}
              isIdle={isIdle}
            />
            <Text size="1" color="gray">
              {getJobStatusText()}
            </Text>
          </SidebarMenuButton>
        </Popover.Trigger>
        <Popover.Content onClick={onTogglePopover} side="right" sideOffset={-1}>
          <Flex direction="column" gap="3">
            <CostBasisJobStatus setIsOpenPopover={setIsOpen} />
            {currentStep && <ClosingProcessJobStatus />}
            <RulesJobStatus />
          </Flex>
        </Popover.Content>
      </Popover.Root>
    </SidebarMenuItem>
  );
}
