import {
  Dialog,
  Flex,
  FlexProps,
  IconButton,
  Separator,
} from '@radix-ui/themes';
import { X } from 'lucide-react';
import { type ReactNode } from 'react';

export function Modal({
  children,
  className,
  size = '2',
  maxWidth = '1000px',
  ...props
}: Dialog.RootProps & {
  className?: string;
  hide?: boolean;
  size?: Dialog.ContentProps['size'];
  maxWidth?: Dialog.ContentProps['maxWidth'];
}) {
  return (
    <Dialog.Root {...props}>
      <Dialog.Content
        size={size}
        className={className}
        maxWidth={maxWidth}
        aria-describedby="modal-description"
      >
        <Flex direction="column" gap="4">
          {children}
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

interface ModalHeaderProps {
  title: string | ReactNode;
  children?: ReactNode;
  closeButton?: boolean;
}
export function ModalHeader({
  title,
  children,
  closeButton = true,
}: ModalHeaderProps) {
  return (
    <>
      <Flex direction="row" justify="between" align="center" p="2">
        <Dialog.Title mb="0">{title}</Dialog.Title>
        {closeButton && (
          <Dialog.Close aria-label="Close">
            <IconButton variant="ghost" size="3">
              <X size="20" />
            </IconButton>
          </Dialog.Close>
        )}
      </Flex>
      {children}
      <Flex direction="row">
        <Separator size="4" />
      </Flex>
    </>
  );
}

export type ModalBodyProps = FlexProps;
export const ModalBody = ({
  children,
  p = '2',
  direction = 'column',
  ...props
}: ModalBodyProps) => {
  return (
    <Flex p={p} direction={direction} {...props}>
      {children}
    </Flex>
  );
};
export const ModalDescription = Dialog.Description;
