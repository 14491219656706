import React, { useMemo } from 'react';
import {
  IntegrationCategories,
  IntegrationSyncMethod,
} from 'src/common/Integration';
import { Integration, IntegrationFormType } from 'src/types/graphql-types';
import { createWalletConnectIntegration } from '../../utils';
import { ApiKeyAndSecretForm } from './ApiKeyAndSecretForm';
import { ApiKeyForm } from './ApiKeyForm';
import { CustomerIdForm } from './CustomerIdForm';
import ExchangeIntegrationFormWrapper from './ExchangeIntegrationFormWrapper';
import { FileImportForm } from './FileImportForm';
import { FireblocksForm } from './FireblocksForm';
import { MultiAddressWalletForm } from './MultiAddressWalletForm';
import { PartnerRedirectForm } from './PartnerRedirectForm';
import { PassphraseForm } from './PassphraseForm';
import { PublicAddressForm } from './PublicAddressForm';
import { PublicXPubForm } from './PublicXPubForm';
import { TwoXPubForm } from './TwoXpubForm';
import { WalletConnect } from './WalletConnectForm';

interface IntegrationFormProps {
  integration: Integration;
  defaultWalletAddress?: string;
  integrations: Integration[];
  onCloseModal?: () => void;
}

export default function IntegrationForm({
  integration,
  defaultWalletAddress,
  integrations,
  onCloseModal,
}: IntegrationFormProps) {
  const defaultCryptoIntegrations = useMemo(() => {
    return integrations.filter(
      (integration) =>
        integration.category === IntegrationCategories.blockchain,
    );
  }, [integrations]);

  const supportedCryptoIntegrations = useMemo(() => {
    return (
      integration.info?.supportedCryptoNetworks &&
      integrations.filter((currIntegration) =>
        integration.info?.supportedCryptoNetworks.includes(
          currIntegration.slug,
        ),
      )
    );
  }, [integrations, integration.info?.supportedCryptoNetworks]);
  if (integration.slug === createWalletConnectIntegration().slug) {
    return (
      <WalletConnect integration={integration} onCloseModal={onCloseModal} />
    );
  } else if (integration.category === IntegrationCategories.blockchain) {
    return (
      <PublicAddressForm
        integration={integration}
        defaultWalletAddress={defaultWalletAddress}
        onCloseModal={onCloseModal}
      />
    );
  }
  if (integration.category === IntegrationCategories.wallet) {
    if (
      integration.formId === IntegrationFormType.Public ||
      integration.formId === IntegrationFormType.PublicXpub
    ) {
      return (
        <PublicXPubForm
          integration={integration}
          cryptoIntegrations={
            supportedCryptoIntegrations ?? defaultCryptoIntegrations
          }
          onCloseModal={onCloseModal}
        />
      );
    } else if (integration.formId === IntegrationFormType.TwoXpub) {
      return (
        <TwoXPubForm
          integration={integration}
          cryptoIntegrations={
            supportedCryptoIntegrations ?? defaultCryptoIntegrations
          }
          onCloseModal={onCloseModal}
        />
      );
    } else if (integration.formId === IntegrationFormType.MultiAddress) {
      return (
        <MultiAddressWalletForm
          integration={integration}
          onCloseModal={onCloseModal}
        />
      );
    } else {
      return (
        <FileImportForm integration={integration} onCloseModal={onCloseModal} />
      );
    }
  }

  if (integration.category === IntegrationCategories.exchange) {
    if (integration.formId === IntegrationFormType.Address) {
      return (
        <PublicAddressForm
          integration={integration}
          defaultWalletAddress={defaultWalletAddress}
          onCloseModal={onCloseModal}
        />
      );
    }
    if (
      integration.syncMethod === IntegrationSyncMethod.AutoSync ||
      integration.syncMethod === IntegrationSyncMethod.AutoSyncAndFile
    ) {
      if (integration.formId === IntegrationFormType.ApiKey) {
        return (
          <ExchangeIntegrationFormWrapper
            integration={integration}
            onCloseModal={onCloseModal}
          >
            <ApiKeyForm integration={integration} onCloseModal={onCloseModal} />
          </ExchangeIntegrationFormWrapper>
        );
      }
      if (integration.formId === IntegrationFormType.Passphrase) {
        return (
          <ExchangeIntegrationFormWrapper
            integration={integration}
            onCloseModal={onCloseModal}
          >
            <PassphraseForm
              integration={integration}
              onCloseModal={onCloseModal}
            />
          </ExchangeIntegrationFormWrapper>
        );
      }
      if (integration.formId === IntegrationFormType.CustomerId) {
        return (
          <ExchangeIntegrationFormWrapper
            integration={integration}
            onCloseModal={onCloseModal}
          >
            <CustomerIdForm
              integration={integration}
              onCloseModal={onCloseModal}
            />
          </ExchangeIntegrationFormWrapper>
        );
      }
      if (integration.formId === IntegrationFormType.Coinbase) {
        return (
          <ExchangeIntegrationFormWrapper
            integration={integration}
            onCloseModal={onCloseModal}
          >
            <PartnerRedirectForm integration={integration} />
          </ExchangeIntegrationFormWrapper>
        );
      }
      if (integration.formId === IntegrationFormType.Coinjar) {
        return (
          <ExchangeIntegrationFormWrapper
            integration={integration}
            onCloseModal={onCloseModal}
          >
            <PartnerRedirectForm integration={integration} />
          </ExchangeIntegrationFormWrapper>
        );
      }
      if (integration.formId === IntegrationFormType.Uphold) {
        return (
          <ExchangeIntegrationFormWrapper
            integration={integration}
            onCloseModal={onCloseModal}
          >
            <PartnerRedirectForm integration={integration} />
          </ExchangeIntegrationFormWrapper>
        );
      }

      if (integration.slug === 'fireblocks') {
        return (
          <ExchangeIntegrationFormWrapper
            integration={integration}
            onCloseModal={onCloseModal}
          >
            <FireblocksForm integration={integration} />
          </ExchangeIntegrationFormWrapper>
        );
      }
      // By default all exchanges with autosync should be using an api/secret form unless otherwise specified
      return (
        <ExchangeIntegrationFormWrapper
          integration={integration}
          onCloseModal={onCloseModal}
        >
          <ApiKeyAndSecretForm integration={integration} />
        </ExchangeIntegrationFormWrapper>
      );
    } else {
      // exchanges with no api importer should show the file import form
      return (
        <FileImportForm integration={integration} onCloseModal={onCloseModal} />
      );
    }
  }

  return (
    <FileImportForm integration={integration} onCloseModal={onCloseModal} />
  );
}
