import { Card, Flex, Heading } from '@radix-ui/themes';
import { useContext } from 'react';
import { EnterpriseJobContext } from '../EnterpriseJobProvider';
import { JobStatusIcon } from './JobStatusIcon';

export const RulesJobStatus = () => {
  const { isRulesEngineJobRunning } = useContext(EnterpriseJobContext);

  return (
    <Card variant="ghost">
      <Flex direction="column" gap="2" width="400px">
        <Flex direction="row" justify="between" align="center">
          <Flex direction="row" gap="2" align="center">
            <JobStatusIcon
              isRunning={isRulesEngineJobRunning}
              hasError={false}
              isIdle={!isRulesEngineJobRunning}
            />
            <Heading size="3">
              {isRulesEngineJobRunning ? 'Applying rules' : 'All rules applied'}
            </Heading>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
