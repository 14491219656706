import { Modal, ModalBody, ModalHeader } from '@/components/Modal';
import { SearchBar } from '@/components/SearchBar';
import { Flex, IconButton, ScrollArea } from '@radix-ui/themes';
import { useAppKitState } from '@reown/appkit/react';
import { isEmpty } from 'lodash';
import { ChevronLeft } from 'lucide-react';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { IntegrationSyncMethod } from 'src/common/Integration';
import useDebounce from 'src/hooks/useDebounce';
import {
  CryptoNetworkResult,
  Integration,
  useGetDetectedCryptoNetworksLazyQuery,
} from 'src/types/graphql-types';
import IntegrationForm from './IntegrationForm';
import { SearchResults } from './SearchResults';
import { CSVIntegration } from './SearchResults/BlankSearchResults';
import { WalletIcon } from './WalletIcon';
import { getIntegrationNameParamFromURL } from './context';

export interface AddWalletModalProps {
  isOpen?: boolean;
  close?: () => void;
  loading?: boolean;
  onHide?: () => void;
  integrations: Integration[];
  selectedIntegration?: Integration;
  setSelectedIntegration?: Dispatch<SetStateAction<Integration>>;
  modalTitle?: string;
  isOnboarding?: boolean;
  isHidden?: boolean;
}

export const AddWalletModal = (props: AddWalletModalProps) => {
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [integrations, setIntegrations] = React.useState<Integration[]>([]);
  const { open: appKitOpen } = useAppKitState();
  const debouncedSearchTerm = useDebounce(searchQuery, 100);
  const [isDetectedCryptoNetwork, setIsDetectedCryptoNetwork] =
    React.useState<boolean>(false);
  const [getDetectedCryptoNetworks, { loading }] =
    useGetDetectedCryptoNetworksLazyQuery();

  const getFilteredIntegrationsList = useCallback(
    async function getFilteredIntegrationsList(searchQuery: string) {
      const filteredIntegrationList = props.integrations.filter(
        (integration) => {
          return (
            integration.name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            integration.slug
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            integration?.info?.symbol
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase())
          );
        },
      );
      if (filteredIntegrationList.length) {
        filteredIntegrationList.sort((a, b) => a.rank - b.rank);
        setIntegrations(filteredIntegrationList);
        setIsDetectedCryptoNetwork(false);
      } else {
        let response;
        try {
          response = await getDetectedCryptoNetworks({
            variables: { address: searchQuery },
          });
        } catch (error) {
          console.error(error);
        }
        const detectedCryptoNetworks =
          response?.data?.detectedCryptoNetworks || [];
        const matchingIntegrationsFromDetectedCryptoNetworks =
          props.integrations.filter((integration) => {
            return detectedCryptoNetworks
              .map((cryptoNetwork: CryptoNetworkResult) =>
                cryptoNetwork.cryptoNetworkName.toLowerCase(),
              )
              .includes(integration.name.toLowerCase());
          });
        matchingIntegrationsFromDetectedCryptoNetworks.sort(
          (a, b) => a.rank - b.rank,
        );
        setIntegrations(matchingIntegrationsFromDetectedCryptoNetworks);
        setIsDetectedCryptoNetwork(true);
      }
    },
    [getDetectedCryptoNetworks, props.integrations],
  );

  useEffect(() => {
    getFilteredIntegrationsList(debouncedSearchTerm);
  }, [debouncedSearchTerm, getFilteredIntegrationsList]);

  useEffect(() => {
    if (!isEmpty(props.integrations)) {
      getFilteredIntegrationsList(searchQuery);
    }
  }, [props.integrations]);

  const clearIntegration = useCallback(() => {
    props.setSelectedIntegration(null);
  }, [props]);

  const searchOnTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    [setSearchQuery],
  );

  const getTitle = useCallback((integration: Integration) => {
    if (integration.name === CSVIntegration.name) {
      return 'Import transactions from CSV';
    } else {
      return `Add ${integration.name}`;
    }
  }, []);

  const onOpenChange = () => {
    if (appKitOpen) return;
    props.onHide();
    setSearchQuery('');
  };

  const integrationNameFromURL = getIntegrationNameParamFromURL();

  const getWalletIcon = useCallback((integration: Integration) => {
    return integration.slug === CSVIntegration.slug ? null : (
      <WalletIcon integration={integration} />
    );
  }, []);

  const hasCSV: string[] = [
    IntegrationSyncMethod.CustomFile,
    IntegrationSyncMethod.AutoSyncAndFile,
    IntegrationSyncMethod.CoinTrackerCSV,
  ];
  const shouldUseWiderWidth =
    props.selectedIntegration?.category === 'Exchange' ||
    hasCSV.includes(props.selectedIntegration?.syncMethod);

  return (
    <Modal
      open={props.isOpen}
      onOpenChange={onOpenChange}
      maxWidth={
        props.selectedIntegration && !shouldUseWiderWidth ? '488px' : '800px'
      }
    >
      {!props.selectedIntegration && !integrationNameFromURL && (
        <>
          <ModalHeader title="Add wallet">
            <SearchBar
              placeholder="Search for your integration or paste your public address, HD wallet key, or ENS"
              onChange={searchOnTextChange}
              value={searchQuery}
              size="3"
              onClear={() => setSearchQuery('')}
              autoFocus
            />
          </ModalHeader>

          <ModalBody>
            <ScrollArea
              type="scroll"
              scrollbars="vertical"
              style={{ height: 500, paddingRight: 16 }}
            >
              <SearchResults
                integrations={integrations}
                loading={props.loading || loading}
                setSelectedIntegration={props.setSelectedIntegration}
                searchQuery={searchQuery}
              />
            </ScrollArea>
          </ModalBody>
        </>
      )}

      {props.selectedIntegration && (
        <>
          <ModalHeader
            title={
              <Flex direction="row" align="center" gap="3">
                <IconButton
                  color="gray"
                  variant="ghost"
                  onClick={clearIntegration}
                >
                  <ChevronLeft size="20" />
                </IconButton>
                {getWalletIcon(props.selectedIntegration)}
                {getTitle(props.selectedIntegration)}
              </Flex>
            }
          />
          <ModalBody gap="4">
            <IntegrationForm
              integration={props.selectedIntegration}
              defaultWalletAddress={
                isDetectedCryptoNetwork ? searchQuery : null
              }
              integrations={props.integrations}
              onCloseModal={onOpenChange}
            />
          </ModalBody>
        </>
      )}
    </Modal>
  );
};
