import { useGetAppInfoQuery } from '@/types/graphql-types';
import { useEffect, useState } from 'react';
interface AppInfo {
  orgId: number;
  orgName: string;
  trialExpiresAt: string;
  isOnTrial: boolean;
  userId: string;
  anonymousId: string;
  email: string;
}

export const useGetAppInfo = ({ skip } = { skip: false }) => {
  const [appInfo, setAppInfo] = useState<AppInfo | undefined>(undefined);
  const [skipAppInfo, setSkipAppInfo] = useState(skip);
  const [isLoading, setIsLoading] = useState(true);
  const { data, loading, error, refetch } = useGetAppInfoQuery({
    errorPolicy: 'all',
    skip: skipAppInfo,
  });

  useEffect(() => {
    setSkipAppInfo(skip);
  }, [skip]);

  useEffect(() => {
    setIsLoading(loading);
    if (data && !loading) {
      setAppInfo({
        orgId: data?.organization?.id,
        orgName: data?.organization?.name,
        trialExpiresAt: data?.organization?.trialExpiresAt,
        isOnTrial: data?.organization?.isOnTrial,
        userId: data?.userInfo?.publicId,
        anonymousId: data?.userInfo?.anonymousId,
        email: data?.userProfile?.email,
      });
    }
  }, [data, loading]);

  return {
    appInfo,
    loading: isLoading,
    error,
    refetch,
  };
};
