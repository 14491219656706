import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { AppKitProvider } from './AppKitProvider';
import { client } from './root/ApolloClient';
import { router } from './router/router';

export const App = () => {
  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <AppKitProvider>
          <RouterProvider router={router} />
        </AppKitProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
};
