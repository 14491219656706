import { CostBasisContext } from '@/components/CostBasisProvider';
import { Status, ToastContext } from '@/components/ToastNotification';
import {
  QueuePriority,
  useEnqueueCostBasisMutation,
} from '@/types/graphql-types';
import { Button, Card, Flex, Heading, Text } from '@radix-ui/themes';
import { formatDistanceToNow, isBefore, subMinutes } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useCallback, useContext } from 'react';
import { JobStatusIcon } from './JobStatusIcon';

export const CostBasisJobStatus = ({
  setIsOpenPopover,
}: {
  setIsOpenPopover: (isOpen: boolean) => void;
}) => {
  const { isCostBasisRunning, hasError, lastSyncedAt } =
    useContext(CostBasisContext);
  const { showNotification } = useContext(ToastContext);

  const lastUpdatedAt = lastSyncedAt
    ? formatDistanceToNow(new Date(lastSyncedAt), {
        addSuffix: true,
        locale: enUS,
      })
    : '';

  const fiveMinutesAgo = subMinutes(new Date(), 5);
  const hasMoreThan5MinutesPassedSinceLastSync =
    lastSyncedAt && isBefore(lastSyncedAt, fiveMinutesAgo);

  const shouldAllowUserToResync =
    isCostBasisRunning && hasMoreThan5MinutesPassedSinceLastSync;

  const [mutate, { loading }] = useEnqueueCostBasisMutation({
    onCompleted: (data) => {
      setIsOpenPopover(false);
      if (data.enqueueCostBasis.success) {
        showNotification({
          status: Status.Success,
          message: 'Enqueued portfolio and tax calculations.',
          delay: 4000,
        });
      } else {
        showNotification({
          status: Status.Error,
          message:
            'There was a problem while enqueuing portfolio and tax calculations. Please try again',
        });
      }
    },
    onError: () => {
      setIsOpenPopover(false);
      showNotification({
        status: Status.Error,
        message:
          'There was a problem while enqueuing portfolio and tax calculations. Please try again',
      });
    },
  });

  const resync = useCallback(() => {
    mutate({ variables: { priority: QueuePriority.High } });
  }, [mutate]);

  const isIdle = !isCostBasisRunning && !hasError;
  return (
    <Card variant="ghost">
      <Flex direction="column" gap="2" width="400px">
        <Flex direction="row" justify="between" align="center">
          <Flex direction="row" gap="2" align="center">
            <JobStatusIcon
              isRunning={isCostBasisRunning}
              hasError={hasError}
              isIdle={isIdle}
            />
            <Heading size="3">Cost basis calculations</Heading>
          </Flex>
          {isIdle && (
            <Text size="1" color="gray">
              {lastSyncedAt ? lastUpdatedAt : 'now'}
            </Text>
          )}
        </Flex>
        {(hasError || isCostBasisRunning) && (
          <Flex direction="row" mx="2">
            {hasError && (
              <Text size="1" color="gray">
                Your cost basis calculations went unexpectingly wrong. Please
                contact support.
              </Text>
            )}
            {isCostBasisRunning && (
              <Text size="1" color="gray">
                Gains and losses, and transaction errors will be available soon.
              </Text>
            )}
          </Flex>
        )}
        {shouldAllowUserToResync && (
          <Flex direction="row" gap="2" mx="2" my="2">
            <Text size="1" color="gray">
              If your calculations take too long, you can try rescheduling it.
            </Text>
            <Button onClick={resync} disabled={loading} size="1">
              Retry
            </Button>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};
