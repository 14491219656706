import {
  useAppKitAccount,
  useAppKitNetwork,
  useAppKitTheme,
  useDisconnect,
} from '@reown/appkit/react';
import { isEmpty } from 'lodash';
import {
  default as React,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserContext } from 'src/app/user';
import { getNiceWalletName } from 'src/common/walletName';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import Banner from 'src/deprecated-components/Banner/Banner';
import { ModalError } from 'src/deprecated-components/Modal/styles';
import {
  Status,
  ToastContext,
} from 'src/deprecated-components/ToastNotification';
import { GetWallets } from 'src/pages/wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsForWalletListQuery,
  Integration,
  useAddPublicAddressMutation,
} from 'src/types/graphql-types';
import { useTheme } from 'styled-components';
import { AddWalletContext } from '../../context';

import { TextFormField } from '../FormBaseComponents/TextFormField';
import { ViewPermissions } from '../FormBaseComponents/ViewPermissions';
import { FormContent, SubmitButton } from '../styles';
import { emptyCacheQuery } from '../util';
import { ErrorMessage } from './ErrorMessage';

interface WalletConnectProps {
  integration: Integration;
  onCloseModal?: () => void;
}

export const WalletConnect = (props: WalletConnectProps) => {
  const { caipNetwork } = useAppKitNetwork();
  const { address: walletAddress } = useAppKitAccount();
  const user = useContext(UserContext);
  const theme = useTheme();
  const { disconnect } = useDisconnect();
  const [walletName, setWalletName] = useState<string>(undefined);
  const onWalletNameChange = useCallback((e) => {
    setWalletName(e.target.value);
  }, []);
  const { setThemeMode } = useAppKitTheme();

  useEffect(() => {
    if (user?.isAuthenticated) {
      if (theme.name.includes('dark')) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode('light');
    }
  }, [user, setThemeMode, theme]);

  const integration = props.integration;
  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<AddWalletErrors>();
  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);

  const [mutate, { loading }] = useAddPublicAddressMutation({
    onCompleted: (data) => {
      if (data?.addPublicAddress?.success) {
        if (data?.addPublicAddress?.warning) {
          showNotification({
            message: `If you have previously added ${integration.info.symbol} transactions manually or marked related transactions as transfers, you may now have duplicate transactions. Please review your ${integration.info.symbol} transactions and if necessary undo those changes.`,
            status: Status.Info,
          });
        }
        showNotification({
          message: data.addPublicAddress.success,
          status: Status.Success,
        });
        const createdWalletOrExchange =
          data.addPublicAddress.createdWallet ||
          data.addPublicAddress.createdExchange;
        onSuccess(createdWalletOrExchange);
        props.onCloseModal?.();
        try {
          disconnect();
        } catch (error) {
          console.error('Error disconnecting wallet', error);
        }
      } else {
        if (data?.addPublicAddress?.error) {
          setSubmitErrorMessage(data?.addPublicAddress?.error);
        } else {
          setSubmitErrorMessage(AddWalletErrors.UnknownError);
        }
      }
    },
    update: (cache, { data }) => {
      if (!data.addPublicAddress.success) {
        return;
      }

      const walletsData: GetWalletsForWalletListQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      if (data.addPublicAddress.createdWallet) {
        const newLocalWallets = [
          ...walletsData.localWallets,
          data.addPublicAddress.createdWallet,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, localWallets: newLocalWallets },
        });
      } else if (data.addPublicAddress.createdExchange) {
        const newExchanges = [
          ...walletsData.exchanges,
          data.addPublicAddress.createdExchange,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, exchanges: newExchanges },
        });
      }
    },
    onError: () => {
      setSubmitErrorMessage(AddWalletErrors.UnknownError);
    },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const kebabNetwork = caipNetwork.name.replace(/\W+/g, '-').toLowerCase();

      mutate({
        variables: {
          publicAddressInput: {
            coinSymbol: caipNetwork.nativeCurrency.symbol,
            cryptoNetwork: kebabNetwork,
            walletAddress,
            walletName,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      mutate,
      caipNetwork,
      walletAddress,
      startFasterPollingForSyncCompletion,
      walletName,
    ],
  );

  const content = (
    <>
      <FormContent>
        <form onSubmit={onSubmit}>
          {submitErrorMessage && (
            <ModalError>
              <Banner
                type="error"
                onBannerClose={() => setSubmitErrorMessage(null)}
              >
                <ErrorMessage error={submitErrorMessage} />
              </Banner>
            </ModalError>
          )}
          <appkit-button data-testid="appkit-button" />
          {!isEmpty(walletAddress) && <appkit-network-button />}

          <TextFormField
            name="wallet_address"
            value={walletName}
            onChange={onWalletNameChange}
            description="Wallet name"
            placeholder={getNiceWalletName(walletAddress, caipNetwork?.name)}
            optional={true}
            autoFocus={true}
          />

          <SubmitButton disabled={loading || !walletAddress} type="submit">
            {loading ? 'Please wait...' : `Add Wallet`}
          </SubmitButton>
        </form>
      </FormContent>
      <ViewPermissions />
    </>
  );
  return <>{content}</>;
};
