import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  Operation,
  ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'cross-fetch';
import { authClient } from '../auth';
import { paginationPolicy } from '../utils/pagination';
import {
  API_URI,
  LIVE_PRICE_DATA_API_URI,
  LIVE_PRICE_DATA_OPERATION_NAME,
} from './constants';

export const apiUri = ({ operationName }: Operation) => {
  // For price data we fetch from a different endpoint
  if (operationName === LIVE_PRICE_DATA_OPERATION_NAME) {
    return `${LIVE_PRICE_DATA_API_URI}?method=${operationName}`;
  }
  return `${API_URI}?method=${operationName}`;
};
const httpLink = new HttpLink({
  uri: apiUri,
  fetch,
});

const authLink = setContext(async (_, { headers }) => {
  let accessToken = null;
  try {
    accessToken = await authClient.getTokenSilently();
  } catch (e) {
    console.error('Error getting access token: ', e?.message);
  }
  return {
    headers: {
      ...headers,
      ...(!accessToken ? {} : { Authorization: `Bearer ${accessToken}` }),
    },
  };
});

// Check if the server responded back with a 401 if so and we are logged in, then logout the SPA
const logoutLink = onError(({ networkError }) => {
  const isUserLoggedIn = authClient.isAuthenticated();
  // Apollo type issue requires us to cast to ServerError - https://github.com/apollographql/apollo-client/issues/7293#issuecomment-731618265
  if (
    networkError &&
    (networkError as ServerError).statusCode === 401 &&
    isUserLoggedIn
  ) {
    console.log('Logging out user due to 401');
    authClient.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }
});

export const client = new ApolloClient({
  link: logoutLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          journalEntries: {
            ...paginationPolicy([
              'journalEntryId',
              'offset',
              'limit',
              'orderBy',
              'desc',
              'status',
              'periodId',
              'accountId',
              'createdAtGte',
              'createdAtLte',
              'isMapped',
              'isBalanced',
              'isSynced',
            ]),
          },
          accounts: {
            ...paginationPolicy(['name']),
          },
          users: {
            ...paginationPolicy(),
          },
          periods: {
            ...paginationPolicy(),
          },
        },
      },
      UserProfile: {
        keyFields: ['publicId'],
      },
      UserInfo: {
        keyFields: ['publicId'],
      },
      Flow: {
        // TODO: We need to remove this after testing. Apollo is merging flows cause they have the same id across operations
        //       and operations don't have id's either so there's no good way to make them unique to the eyes of Apollo
        keyFields: ['id', 'amount'],
      },
      SearchCurrency: {
        keyFields: ['id', 'symbol'],
      },
      // FST-3799: We need to merge syncInfo objects for exchanges and wallets because
      // they have no unique id and we use different fields for them across different queries
      Exchange: {
        fields: {
          syncInfo: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
      // FST-3799: Same as above but for wallets
      Wallet: {
        fields: {
          syncInfo: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
      Currency: {
        keyFields: (obj) => {
          const key = ['symbol'];
          if (obj?.cmcId) key.push('cmcId');
          if (obj?.name) key.push('name');
          return key;
        },
      },
    },
  }),
});
