import {
  OrganizationPeriod,
  PeriodStatus,
  useGetOrganizationQuery,
  useGetPeriodsQuery,
} from '@/types/graphql-types';
import { isBefore, parse, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

export const useGetPeriods = () => {
  const { data: organizationData } = useGetOrganizationQuery();
  const res = useGetPeriodsQuery();
  const { data, loading } = res;
  const [periods, setPeriods] = useState<OrganizationPeriod[]>();
  const [loadingPeriods, setLoadingPeriods] = useState(true);
  useEffect(() => {
    if (!data?.periods || loading) return;

    if (organizationData?.organization?.info?.migration_date) {
      const parsedDate = parse(
        organizationData.organization.info.migration_date,
        'yyyy-MM-dd',
        new Date(),
      );
      setLoadingPeriods(false);
      setPeriods(
        // Only show periods after or equal to the migration date
        data?.periods?.page?.objects?.filter(
          (period) => !isBefore(parseISO(period.startDate), parsedDate),
        ),
      );
    } else {
      setPeriods(data?.periods?.page?.objects);
      setLoadingPeriods(false);
    }
  }, [organizationData?.organization?.info?.migration_date, data, loading]);

  return { ...res, periods, loading: loadingPeriods };
};

export const getPeriodReviewChecks = (period: OrganizationPeriod) => {
  const info = period.info ? JSON.parse(period.info) : {};
  // If the period is closed, we don't need to check for reviewed status
  const reviewChecks =
    period.status === PeriodStatus.Open
      ? {
          journalEntries: info.journal_entries ?? false,
          transactions: info.transactions ?? false,
          balanceDifferences: info.balance_differences ?? false,
          incomeStatement: info.income_statement ?? false,
          holdings: info.holdings ?? false,
        }
      : {
          journalEntries: true,
          transactions: true,
          balanceDifferences: true,
          incomeStatement: true,
          holdings: true,
        };
  return new Map<string, boolean>(Object.entries(reviewChecks));
};

export const isPeriodReviewed = (period: OrganizationPeriod) => {
  const reviewChecks = getPeriodReviewChecks(period);
  return Array.from(reviewChecks.values()).every((value) => value);
};

export const getPeriodReviewProgress = (period: OrganizationPeriod) => {
  const reviewChecks = getPeriodReviewChecks(period);
  const reviewedCount = Array.from(reviewChecks.values()).filter(
    (reviewed) => reviewed,
  ).length;
  return Math.round((reviewedCount / reviewChecks.size) * 100);
};
