import { useAuth0 } from '@auth0/auth0-react';
import { StatsigClient } from '@statsig/js-client';
import { useMemo, useState } from 'react';
import { isEmployee } from '../user';
import { useGetAppInfo } from './appInfoContext';

export const useAsyncClient = (): {
  isLoading: boolean;
  client: StatsigClient;
} => {
  const { isLoading: isLoadingAuth, isAuthenticated } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const { appInfo, loading: isAppInfoLoading } = useGetAppInfo({
    skip: isLoadingAuth || !isAuthenticated,
  });

  const client = useMemo(() => {
    if (isAppInfoLoading || isLoadingAuth) return;

    if (!isAuthenticated || !appInfo) {
      setIsLoading(false);
      console.log(
        `User is not authenticated. Can't initialize Statsig client. Loading an empty instance! isAuthenticated: ${isAuthenticated}, appInfo: ${appInfo}`,
      );
      return new StatsigClient(import.meta.env.PUBLIC_STATSIG_CLIENT_KEY, {});
    }

    const instance = new StatsigClient(
      import.meta.env.PUBLIC_STATSIG_CLIENT_KEY,
      {
        userID: appInfo.userId,
        customIDs: {
          anonymousID: appInfo.anonymousId,
        },
        custom: {
          organizationId: appInfo.orgId?.toString(),
          is_employee: isEmployee(appInfo.email ?? ''),
          isAnonymous: !appInfo.email,
        },
      },
    );

    instance
      .initializeAsync()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    return instance;
  }, [isAppInfoLoading, isLoadingAuth, isAuthenticated, appInfo]);

  return { client, isLoading };
};
